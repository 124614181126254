<template>
  <div class="poster-box" id="poster-html">
    <!-- 顶部图片 -->
    <img style="width:100%" :src="img" alt="">
    <!-- 步骤说明 -->
    <div class="instruction">
      <div style="white-space:pre-wrap;font-size:18px" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import axios from 'axios'
export default {
  data () {
    return {
      refer: "start",
      img: "",
      content: '',
      title: '',
      visitorId: '', //唯一机器码
    }
  },
  created () {
    this.getVisitorId()
  },
  methods: {
    //使用@fingerprintjs/fingerprintjs库获取唯一机器码
    getVisitorId () {
      const fpPromise = FingerprintJS.load()
        ; (async () => {
          // Get the visitor identifier when you need it.
          const fp = await fpPromise
          const result = await fp.get()

          // This is the visitor identifier:
          const visitorId = result.visitorId
          console.log(visitorId)
          this.visitorId = visitorId
          //获取到唯一机器码后 调用接口
          this.getUserInfo()
        })()
    },

    //获取用户数据信息
    async getUserInfo () {
      let project = this.$route.query.project
      let refer = this.$route.query.refer
      let origin_ = window.location.origin
      let path_ = window.document.location.pathname
      console.log(origin_ + path_);
      console.log(this.visitorId);
      const res = await axios.get(`${origin_}/backend/task?project=${project}&refer=${refer}&referrer=${origin_ + path_}&visitorId=${this.visitorId}`)
      //const res = await axios.get(`https://dev.poster.ceba.ceshiren.com/backend/dev/task?project=${project}&refer=${refer}&referrer=${origin_ + path_}&visitorId=${this.visitorId}`)
      console.log(res);
      this.title = res.data.title
      this.img = res.data.img
      this.content = res.data.content
      // this.qrcode_uri = res.data.img
      if (res.data.headimgurl) {
        console.log(123);
        this.headimgurl = res.data.headimgurl
      }
      if (res.data.nickname) {
        console.log(456);
        this.nickname = res.data.nickname
      }
    },
  },
  mounted () {
    console.log(this.title);
    document.title = this.title
  },
  watch: {
    title: {
      handler (newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  }
}
</script>

<style scoped>
.poster-box {
  position: absolute;
  top: 0px;
}
.bg-img {
  width: 100%;
  height: 100%;
}
.instruction {
  width: 100%;

  color: #333;
  padding: 0 1rem;
  margin-top: 10px;
}

.v-application p {
  margin: 0;
  font-size: 18px;
  word-wrap: break-word;
}
.qrcode {
  width: 4rem;
  height: 4rem;
  margin: 1.8rem auto 0;
}
.qrcode .qrcode-img {
  width: 100%;
}
</style>